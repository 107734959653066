<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="540">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <form-subheader title="General fields" />
          <validation-provider
            v-slot="{ errors }"
            name="Property name"
            rules="required"
          >
            <v-text-field
              v-model="state.property"
              label="Property name"
              :error-messages="errors"
            >
              {{ state.property }}
            </v-text-field>
          </validation-provider>
          <!--        <v-text-field v-model="state.longName" label="Long name">-->
          <!--          {{ state.longName }}-->
          <!--        </v-text-field>-->
          <v-select
            v-model="state.typeId"
            label="Type"
            :items="types"
            item-text="name"
            item-value="value"
          >
            {{ state.typeId }}
          </v-select>
          <form-switcher v-model="state.hidden" title="Hidden" />
          <form-switcher v-model="state.stealth" title="Stealth" />
          <form-subheader title="Optional fields" />
          <v-text-field v-model="state.units" label="Units">
            {{ state.units }}
          </v-text-field>
          <v-text-field v-model="state.defaultValue" label="Default Value">
            {{ state.defaultValue }}
          </v-text-field>
          <v-text-field v-model="state.regex" label="Regex">
            {{ state.regex }}
          </v-text-field>
          <v-text-field
            v-model="state.valueRange"
            label="Value range"
            placeholder='{"min": 0, "max": 100}'
          >
            {{ state.valueRange }}
          </v-text-field>
          <v-text-field
            v-model="state.valueSet"
            label="Value set"
            placeholder='{"name1": "value1", "name2": "value2"}'
          >
            {{ state.valueSet }}
          </v-text-field>
          <v-text-field v-model="state.mask" label="Mask">
            {{ state.mask }}
          </v-text-field>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="invalid"
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { schemasService } from '@/modules/common/api';
import { typeCast } from '@/provider/utils';
import { isJson } from '@/utils/getCurrentType';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

const typesDefinition = {
  string: '00000000-0000-0000-0000-000000000001',
  number: '00000000-0000-0000-0000-000000000002',
  bool: '00000000-0000-0000-0000-000000000003',
  object: '00000000-0000-0000-0000-000000000004',
  array: '00000000-0000-0000-0000-000000000005'
};

export default {
  name: 'PropertyEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    schemaId: {
      type: String,
      default: ''
    },
    propertyId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const form = ref(null);
    const state = ref({
      property: '',
      typeId: typesDefinition.string,
      hidden: false,
      stealth: false,
      units: '',
      defaultValue: null,
      regex: '',
      valueRange: '',
      valueSet: '',
      mask: ''
    });

    const edit = computed(() => props.propertyId);
    const title = computed(() =>
      edit.value ? 'Edit property' : 'Add property'
    );
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const types = ref(
      Object.entries(typesDefinition).map(([name, value]) => ({ name, value }))
    );

    const typeName = computed(
      () => types.value.find(type => type.value === state.value.typeId)?.name
    );

    const handleSubmit = async () => {
      const defaultValue = isJson(state.value.typeId)
        ? JSON.parse(state.value.defaultValue)
        : typeCast(typeName.value, state.value.defaultValue);
      const payload = {
        ...state.value,
        defaultValue,
        ...(state.value.valueRange
          ? { valueRange: JSON.parse(state.value.valueRange) }
          : {}),
        ...(state.value.valueSet
          ? { valueSet: JSON.parse(state.value.valueSet) }
          : {})
      };
      if (!edit.value) {
        schemasService.createProperty({
          schemaId: props.schemaId,
          ...payload
        });
      } else {
        schemasService.updateProperty(props.propertyId, payload);
      }
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (edit.value) {
        state.value = await schemasService.fetchProperty(props.propertyId);
        state.value.defaultValue = !state.value.defaultValue
          ? ''
          : JSON.stringify(state.value.defaultValue);
        state.value.valueRange = !state.value.valueRange
          ? ''
          : JSON.stringify(state.value.valueRange);
        state.value.valueSet = !state.value.valueSet
          ? ''
          : JSON.stringify(state.value.valueSet);
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      types,
      form
    };
  }
};
</script>
